jQuery(document).foundation();

/*
These functions make sure WordPress
and Foundation play nice together.
*/


//----------Misc---------------------

// links block - reformatting wysiwyg content
jQuery(document).ready(function(){
    jQuery(".links-block a").wrap("<p></p>");
});

// make voucher category in woo go off to external link
jQuery(document).ready(function(){
  jQuery(".sidebar .cat-item-82").click(function(){
      jQuery(".sidebar .cat-item-82 a").attr("href", "https://coombeabbey.wearegifted.co.uk/");
      jQuery(".sidebar .cat-item-82 a").attr("target", "_blank");

  });
});


jQuery(document).ready(function(){
  setTimeout(
  function()
  {
    jQuery( ".woocommerce-mailchimp-opt-in" ).prepend( "<p><b>Would you like to opt in to our mailing list?</b></p>" );
  }, 3000);
});


//----------vide safari hack - https://github.com/VodkaBears/Vide/issues/206

jQuery(document).ready(function() {
	//FORCE VIDEO PLAY - SAFARI 11 ISSUE
	jQuery(window).load(function() {
		jQuery('.cover-video').data('vide').getVideoObject().play();
	});
});

//----------Smooth Scrolling---------------------

//smooth anchor scroller

jQuery(document).ready(function(){
  // applies to all links
  jQuery("a").on('click', function(event) {

//store has value clicked on
var hash = this.hash;

//check it does not contain the string tab as we don't want smooth scroll when switching woo tabs
var index = hash.indexOf("tab");

    // checking if this has a value to avoid console error this time, excluding woo tabs from scrolling
    if (this.hash !== "" && (index == -1))  {


      // Using jQuery's animate() method to add smooth page scroll
      jQuery('html, body').animate({
        scrollTop: jQuery(hash).offset().top-100
      }, 1200, function(){

        // Adding hash (#) to URL when done scrolling (default click behavior)
        window.location.hash = hash;
      });
    } // End if
  });
});


//----------Inspire me JS--------wip-------------


// jQuery(document).on('facetwp-refresh', function() {
//     var facet = FWP.facets['perfect_for'];
//     //facet.toString();
//  });
//
//  jQuery( ".update" ).click(function(facet) {
//    console.log(facet);
//    jQuery( ".facet-selections" ).html("<div style='color:white;'>Perfect For: "+facet+" </div>");
//  });

//----------home cover fades on scroll down---------------------

jQuery(document).ready(function(){
	 jQuery(window).scroll(function(){
			 jQuery('.home .cover-moving').css("opacity", 1- jQuery(window).scrollTop() / 600)
	 })
});

//----------vide safari hack - https://github.com/VodkaBears/Vide/issues/206

// if (jQuery(".cover-video")[0]){

  // jQuery(document).ready(function() {
  // 	//FORCE VIDEO PLAY - SAFARI 11 ISSUE
  // 	jQuery(window).load(function() {
  // 		jQuery('.cover-video').data('vide').getVideoObject().play();
  // 	});
  // });

// }

//----------clickable divs---------------------

jQuery(".clickable").click(function(){
 window.location=jQuery(this).find("a").attr("href");
 return false;
});

jQuery(".clickable-blank").click(function(){
  window.open(jQuery(this).find("a:first").attr("href"));
  return false;
});


jQuery(document).ready(function() {

	// Remove empty P tags created by WP inside of Accordion and Orbit
	jQuery('.accordion p:empty, .orbit p:empty').remove();

	// Adds Flex Video to YouTube and Vimeo Embeds
	jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
		if ( jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5 ) {
		  jQuery(this).wrap("<div class='widescreen responsive-embed'/>");
		} else {
		  jQuery(this).wrap("<div class='responsive-embed'/>");
		}
	});
});

//----------headroom.js---------------------

// grab an element
var elem = document.querySelector(".header");
// construct an instance of Headroom, passing the element
var headroom = new Headroom(elem, {
  "offset": 10,
  "tolerance": {
    "up": 0,
    "down": 0
  }
});
// initialise
headroom.init();


//----------owl carousels---------------------

jQuery('.cover-image-carousel').owlCarousel({
	items:1,
	dots:false,
	nav:false,
	loop:true,
	mouseDrag:false,
	autoplay:true,
	autoplayTimeout:6000,
	animateIn: 'fadeIn',
	animateOut: 'fadeOut',
	navText : ["",""]
});


jQuery('.full-image-carousel').owlCarousel({
	items:1,
	dots: true,
	nav:true,
	navText : ["<i aria-label='previous' class='kikor kiko-arrow-left-circle'></i>","<i aria-label='next' class='kikor kiko-arrow-right-circle'></i>"]
});

jQuery('.three-image-carousel').owlCarousel({
	dots: true,
	nav:true,
  loop:true,
	navText : ["<i aria-label='previous' class='kikor kiko-arrow-left-circle'></i>","<i aria-label='next' class='kikor kiko-arrow-right-circle'></i>"],
  responsive : {
    // breakpoint from 0 up
    0 : {
	     items:1
    },
    980 : {
	     items:3
    }
}
});


jQuery('.testimonial-carousel').owlCarousel({
	items:1,
	dots: true,
	nav:false,
});

jQuery('.1-carousel').owlCarousel({
  items:1,
	dots: true,
	nav:true,
	navText : ["<i aria-label='previous' class='kikor kiko-arrow-left-circle'></i>","<i aria-label='next' class='kikor kiko-arrow-right-circle'></i>"]
});
jQuery('.2-carousel').owlCarousel({
  items:1,
	dots: true,
	nav:true,
	navText : ["<i aria-label='previous' class='kikor kiko-arrow-left-circle'></i>","<i aria-label='next' class='kikor kiko-arrow-right-circle'></i>"]
});
jQuery('.3-carousel').owlCarousel({
  items:1,
	dots: true,
	nav:true,
	navText : ["<i aria-label='previous' class='kikor kiko-arrow-left-circle'></i>","<i aria-label='next' class='kikor kiko-arrow-right-circle'></i>"]
});
jQuery('.4-carousel').owlCarousel({
  items:1,
	dots: true,
	nav:true,
	navText : ["<i aria-label='previous' class='kikor kiko-arrow-left-circle'></i>","<i aria-label='next' class='kikor kiko-arrow-right-circle'></i>"]
});
jQuery('.5-carousel').owlCarousel({
  items:1,
	dots: true,
	nav:true,
	navText : ["<i aria-label='previous' class='kikor kiko-arrow-left-circle'></i>","<i aria-label='next' class='kikor kiko-arrow-right-circle'></i>"]
});
jQuery('.6-carousel').owlCarousel({
  items:1,
	dots: true,
	nav:true,
	navText : ["<i aria-label='previous' class='kikor kiko-arrow-left-circle'></i>","<i aria-label='next' class='kikor kiko-arrow-right-circle'></i>"]
});


//----------Map zoom - drift.js---------------------

var options = {
  // Prefix for generated element class names (e.g. `my-ns` will
  // result in classes such as `my-ns-pane`. Default `drift-`
  // prefixed classes will always be added as well.
  namespace: null,
  // Whether the ZoomPane should show whitespace when near the edges.
  showWhitespaceAtEdges: false,
  // Whether the inline ZoomPane should stay inside
  // the bounds of its image.
  containInline: true,
  // How much to offset the ZoomPane from the
  // interaction point when inline.
  inlineOffsetX: 0,
  inlineOffsetY: 0,
  // A DOM element to append the inline ZoomPane to.
  inlineContainer: document.body,
  // Which trigger attribute to pull the ZoomPane image source from.
  sourceAttribute: 'data-zoom',
  // How much to magnify the trigger by in the ZoomPane.
  // (e.g., `zoomFactor: 3` will result in a 900 px wide ZoomPane image
  // if the trigger is displayed at 300 px wide)
  zoomFactor: 2,
  // A DOM element to append the non-inline ZoomPane to.
  // Required if `inlinePane !== true`.
  paneContainer: document.body,
  // When to switch to an inline ZoomPane. This can be a boolean or
  // an integer. If `true`, the ZoomPane will always be inline,
  // if `false`, it will switch to inline when `windowWidth <= inlinePane`
  inlinePane: true,
  // If `true`, touch events will trigger the zoom, like mouse events.
  handleTouch: true,
  // If present (and a function), this will be called
  // whenever the ZoomPane is shown.
  onShow: null,
  // If present (and a function), this will be called
  // whenever the ZoomPane is hidden.
  onHide: null,
  // Add base styles to the page. See the "Theming"
  // section of README.md for more information.
  injectBaseStyles: true,
  // An optional number that determines how long to wait before
  // showing the ZoomPane because of a `mouseenter` event.
  hoverDelay: 0,
  // An optional number that determines how long to wait before
  // showing the ZoomPane because of a `touchstart` event.
  // Setting this to a reasonable amount will allow users to execute
  // scroll-gestures events on touch-enabled devices with the image as
  // a starting point
  touchDelay: 0,
  // If true, a bounding box will show the area currently being previewed
  // during mouse hover
  hoverBoundingBox: false,
  // If true, a bounding box will show the area currently being previewed
  // during touch events
  touchBoundingBox: false,
};

if (jQuery(".zoom-image")[0]){
  new Drift(document.querySelector('.zoom-image'), options);
}
